import React, { ReactElement } from "react";
import { FluidSharpImageFile } from "@ymcansw-camping/common";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import SchoolsLayout from "../../components/schools-layout";

type JourneyData = {
  readonly journeyBasedImage: FluidSharpImageFile;
  readonly personalGrowthImage: FluidSharpImageFile;
  readonly communityImage: FluidSharpImageFile;
  readonly natureImage: FluidSharpImageFile;
};

export default function JourneyPage(): ReactElement {
  const {
    journeyBasedImage,
    personalGrowthImage,
    communityImage,
    natureImage,
  } = useStaticQuery<JourneyData>(graphql`
    {
      journeyBasedImage: file(
        relativePath: { eq: "img/journey-based-program-image.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 720) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      personalGrowthImage: file(
        relativePath: { eq: "img/school-personal-growth.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      communityImage: file(relativePath: { eq: "img/school-community.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      natureImage: file(relativePath: { eq: "img/school-nature.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 550) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      #      pageFile: file(relativePath: { eq: "pages/school-journey.md" }) {
      #        childMarkdownRemark {
      #          frontmatter {
      #            activitiesTableTitle
      #            activities {
      #              title
      #              learningOutcomes
      #            }
      #          }
      #        }
      #      }
    }
  `);

  return (
    <SchoolsLayout
      meta={{
        title: "Journey Based Programs",
        description:
          "The journey based program takes students to the next level with a strong emphasis on leadership, social responsibility and protecting the natural world.",
      }}
    >
      <div className="content-block">
        <h2>Journey based programs</h2>
        <p>
          The journey based program takes students to the next level with a
          strong emphasis on leadership, social responsibility and protecting
          the natural world. Journey based programs can include a variety of
          adventurous and challenging activities that are used as tools to
          assist students in reaching set learning outcomes.
        </p>
        <p>
          The aim is for students to be immersed in a supportive and safe
          environment that allows them to learn through doing.
        </p>
        <p>
          The program will be designed around students taking ownership and
          responsibility for achieving set group objectives. This promotes
          positive individual growth that takes into consideration the physical,
          mental, emotional and social challenges that students may encounter.
        </p>
      </div>

      <div className="content-block row">
        <div className="col-xs-12 col-md-5 col-md-push-7">
          <div className="image-container">
            <Img
              fluid={journeyBasedImage.childImageSharp.fluid}
              alt="journey based program"
            />
          </div>
        </div>
        <div className="col-xs-12 col-md-7 col-md-pull-5">
          <h6 className="mt0">
            Single and combined activities for a Journey Based program could
            include:
          </h6>
          <ul>
            <li>Hiking expeditions</li>
            <li>Canoeing expeditions</li>
            <li>Embedded local Indigenous content and activities</li>
            <li>Roping – natural surface abseil and rock climbing</li>
            <li>Solo reflective opportunity</li>
            <li>Problem solving initiatives</li>
            <li>
              Opportunity to explore and contribute to the environment and
              community
            </li>
            <li>Camp craft and cooking</li>
          </ul>
        </div>
      </div>
      <div className="content-block icon-circle-container">
        <h3 className="h5">Potential Learning Outcomes</h3>
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="icon-circle-block text-center">
              <Img
                fluid={personalGrowthImage.childImageSharp.fluid}
                alt="Personal Growth"
              />
              <h4 className="title">Personal Growth</h4>
              <p>
                Build resilience
                <br />
                Leadership
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="icon-circle-block text-center">
              <Img
                fluid={communityImage.childImageSharp.fluid}
                alt="Sense of Community"
              />
              <h4 className="title">Sense of Community</h4>
              <p>
                Be socially responsible
                <br />
                Solve problems together
              </p>
            </div>
          </div>
          <div className="col-xs-12 col-md-4">
            <div className="icon-circle-block text-center">
              <Img
                fluid={natureImage.childImageSharp.fluid}
                alt="Natural World"
              />
              <h4 className="title">Connect with Natural World</h4>
              <p>Protect the natural world</p>
            </div>
          </div>
        </div>
      </div>

      <div className="content-block">
        <h3 className="h5">Journey based programs including sample program</h3>
        <p className="h6 mt2">Suitable for:</p>
        <ul>
          <li>Primary School – Year 6</li>
          <li>Secondary / High School – Year 7 to Year 12</li>
          <li>Universities</li>
          <li>International Schools</li>
        </ul>
      </div>
      {/* <SchoolActivitiesTable */}
      {/*  title={frontmatter.activitiesTableTitle} */}
      {/*  activities={frontmatter.activities} */}
      {/* /> */}
    </SchoolsLayout>
  );
}
